<template>
  <div class="yingjian_shezhi_box">
    <!-- <div style="fontSize:20px">硬件设置</div> -->

    <div class="Mar-nav">
      <!-- <p :class="navindex == 0 ? 'psel':''" @click="handlechange(0)">营销图设置</p>
      <p :class="navindex == 1 ? 'psel':''" @click="handlechange(1)">优惠活动设置</p> -->
      <el-row :gutter="20">
        <el-col :span="3"
          ><div
            class="grid-content bg-purple"
            :class="navindex == '0' ? 'psel' : ''"
            @click="handlechange('0')"
          >
            打印设置
          </div></el-col
        >
        <el-col :span="3"
          ><div
            class="grid-content bg-purple"
            :class="navindex == '1' ? 'psel' : ''"
            @click="handlechange('1')"
          >
            发票设置
          </div></el-col
        >
        <el-col :span="3"
          ><div
            class="grid-content bg-purple"
            :class="navindex == '2' ? 'psel' : ''"
            @click="handlechange('2')"
          >
            支付设置
          </div></el-col
        >
      </el-row>
    </div>

    <div class="yingjian_dayin" v-if="navindex == '0'">
      <div class="XZ_input">
        <span class="span_line"></span>
        <span class="span_text">适用门店：</span>
        <el-select
          v-model="dayin_dianpu"
          placeholder="请选择"
          @change="handle_dayin_dianpu"
        >
          <el-option
            v-for="item in dyj_options"
            :key="item.shop_id"
            :label="item.shop_name"
            :value="item.shop_id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="xaunze_dayinji_data">
        <el-row :gutter="20">
          <el-col :span="3">
            <div class="yin_j radio_xz">
              <span class="yin_j_span">是否使用打印机</span>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="yin_j radio_xz">
              <el-radio v-model="flag_use" label="1">是</el-radio>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="yin_j radio_xz">
              <el-radio v-model="flag_use" label="2">否</el-radio>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="yin_j" v-if="false">
              <el-input
                placeholder="请输入打印机数量"
                v-model="printer_num"
                class="input-with-select"
              >
                <el-button slot="append" class="dyjsl_btn"> 确认 </el-button>
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <div class="yin_j radio_xz">
              <span class="yin_j_span">是否显示打印次数</span>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="yin_j radio_xz">
              <el-radio v-model="flage_disp_times" label="1">是</el-radio>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="yin_j radio_xz">
              <el-radio v-model="flage_disp_times" label="2">否</el-radio>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="zhifingdayin_neirong">
        <div class="zddy_txt">指定打印内容</div>

        <el-row :gutter="20" v-for="(item, index) in list_printer" :key="index">
          <el-col :span="23" style="height: auto;">
            <div class="grid-content bg_purple_bt dyj_box">
              <div class="dyj_bt">打印机{{ index + 1 }}：</div>
              <el-select
                v-model="item.printer_id"
                placeholder="请选择"
                clearable
                @change="handle_printerList"
              >
                <el-option
                  v-for="item in dayyinji_list"
                  :key="item.name_linshi"
                  :label="item.msn"
                  :value="item.msn"
                  :disabled="item.dis"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="23" style="height: auto;">
            <div class="grid-content bg_purple_bt dyj_box1" style="height: auto;">
              <div><p class="dynr_txt">打印内容:</p></div>
              <div class="dayin_nairong_xz">
                <!-- <el-checkbox-group v-model="item.print_content_type" @change="dayin_ji_xuanze_dayin"> -->
                <el-checkbox v-model="item.print_content_type" label="1">
                  <div class="search">
                    <div class="search_name">预结账单</div>
                    <div class="grid-content bg_purple_bt dyj_box2">
                      <div class="dyj_bt">打印份数:</div>
                      <el-input
                        v-model="item.expected"
                        placeholder="请输入内容"
                        :disabled="!item.print_content_type.includes('1')"
                      ></el-input>
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox v-model="item.print_content_type" label="2">
                  <div class="search">
                    <div class="search_name">点菜单</div>
                    <div class="grid-content bg_purple_bt dyj_box2">
                      <div class="dyj_bt">打印份数:</div>
                      <el-input
                        v-model="item.menu"
                        placeholder="请输入内容"
                        :disabled="!item.print_content_type.includes('2')"
                      ></el-input>
                    </div>
                  </div>
                </el-checkbox>
                <div class="duoxuan_box">
                  <el-checkbox v-model="item.print_content_type" label="3">
                    <div class="search_name">全部菜系</div>
                  </el-checkbox>
                  <div class="search">
                    <el-cascader
                      v-model="item.cat_ids"
                      :options="prod_list"
                      :props="{
                        multiple: true,
                        checkStrictly: true,
                        children: 'prod_children',
                        label: 'prod_name',
                        value: 'prod_id',
                      }"
                      clearable
                      ref="cascader_caipin"
                      placeholder="全部菜系"
                      @change="xuanzecai"
                      :disabled="!item.print_content_type.includes('3')"
                    ></el-cascader>
                    <div class="grid-content bg_purple_bt dyj_box2">
                      <div class="dyj_bt" style="margin-left: 15px; white-space: nowrap;">打印份数:</div>
                      <el-input
                        v-model="item.Alldishes"
                        placeholder="请输入内容"
                        :disabled="!item.print_content_type.includes('3')"
                      ></el-input>
                    </div>
                  </div>
                </div>

                <!-- </el-checkbox-group> -->
              </div>
            </div>
          </el-col>

          <el-col :span="23" style="height: auto;">
            <div class="grid-content bg_purple_bt dyj_box3" style="height: auto;">
              <div class="dyj_bt">打印方式:</div>
              <!-- <el-input v-model="item.print_num" placeholder="请输入内容"></el-input> -->
              <el-select
                v-model="item.print_mode"
                placeholder="请选择"
                @change="dayin_fangshi_fangfa"
              >
                <el-option
                  v-for="item in dayin_fangshi"
                  :key="item.id"
                  :label="item.dayin_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="dayin_baocun">
        <div class="dayin_baocun_btn" @click="printer_baocun">保存</div>
      </div>
    </div>

    <div v-if="navindex == '1'">

        <div class="fapiao_shezhi" >

          <div class="box">
            <div class="title">
              <p>方案一</p>
              <!-- <c-icon name="guanbi"></c-icon> -->
            </div>
            <!--  @click.native="del_fangan(data_list,index,item)" -->
            <div class="box_ul">
              <ul>
                <el-scrollbar style="height: 200px">
                  <li v-for="(ss,indexx) in options_value_box" :key="indexx">
                    <span></span>
                    <p style="flex: 1;">{{ss.shop_name}}</p>
                    <!-- <p @click="del_mendian(ss,indexx)">删除</p> -->
                  </li>
                  <!-- <li>
                    <span></span>
                    <p>门店门店</p>
                    <p>删除</p>
                  </li> -->
                </el-scrollbar>
                <!-- <div class="div-button"><el-button @click="add(item.mendian,index,0)" size="mini" type="danger">新增门店</el-button></div> -->
                <div class="div-button">
                  <el-button @click="add_mendian" size="mini" type="danger">新增门店</el-button>
                </div>
              </ul>
            </div>
            <div class="box1_ul">
              <ul>
                <el-scrollbar style="height: 200px">
                  <!-- <li v-for="(ss,indexx) in item.mendian" :key="indexx"><span></span><p>{{ss.shop_name}}</p><p @click="del_mendian(item.mendian,ss,indexx)">删除</p></li> -->
                  <li>
                    <p>开票方式</p>
                    <p>{{getStatusLabel(this.submitInvoiceForm.open_type, kaipiao_fs_arr, 'id', 'label')}}</p>
                  </li>
                </el-scrollbar>
                <!-- <div class="div-button"><el-button @click="add(item.mendian,index,0)" size="mini" type="danger">新增门店</el-button></div> -->
                <div class="div-button">
                  <el-button @click="add_kaipiao" size="mini" type="danger">选择开票方式</el-button>
                </div>
              </ul>
            </div>
            <!-- 选择门店---开票方式 -->
            <!-- <div class="sidbar" :class="{'active': index === index_mun}" @mouseleave="index_mun = null"> -->
            <div
              class="sidbar"
              v-show="serarch_xz"
              @mouseleave="serarch_xz = false"
            >
              <div class="title"><p>请选择</p></div>
              <div class="main">
                <!-- 添加门店 -->
                <ul v-if="!is_mendian_or_kaipiaofangshi">
                  <el-scrollbar style="height: 100%">
                    <!-- <el-checkbox-group v-model="options_value_box" style="width:100px;" @change="changeItem">
                                <el-checkbox v-for="city in options" :label="city.shop_id" :key="city.shop_id">{{city.shop_name}}</el-checkbox>
                            </el-checkbox-group> -->
                    <div class="mybox">
                      <li v-for="(i,indexi) in dyj_options" :key="indexi" :class="{'end_active':JSON.stringify(options_value_box).includes(i.shop_id)}" @click="changeItem(i,indexi,dyj_options)">
                        <p>{{i.shop_name}}</p>
                        <c-icon name="duigou" v-if="JSON.stringify(options_value_box).includes(i.shop_id)"></c-icon>
                      </li>
                      <!-- <li>
                        <p>门店名</p>
                        <c-icon name="duigou"></c-icon>
                      </li> -->
                    </div>
                  </el-scrollbar>

                  <div class="tijiao">
                    <!-- <el-button  size="mini" @click="push_mendian(item.mendian)"  type="danger">确定</el-button> -->
                  </div>
                </ul>
                <!-- 添加开票方式 -->
                <ul v-else>
                  <el-scrollbar style="height: 420px">
                    <!-- <el-checkbox-group v-model="options_value" style="width:100px;">
                                <el-checkbox v-for="city in data_cai_list" :label="city.prod_id" :key="city.prod_id">{{city.prod_name}}</el-checkbox>
                            </el-checkbox-group> -->
                    <!-- <c-menu @options_value="message_options_value" v-if="is_mendian_or_caipin"></c-menu> -->
                    <el-radio v-model="kaipiao_fs" v-for="(child, kp_index) in kaipiao_fs_arr" :key="kp_index" :label="child.id">{{child.label}}</el-radio>
                    <!-- <el-radio v-model="kaipiao_fs" label="2">手动开票</el-radio>
                    <el-radio v-model="kaipiao_fs" label="3">扫码开票</el-radio> -->
                  </el-scrollbar>
                  <div class="tijiao">
                    <el-button size="mini" @click="push_caipin(kaipiao_fs)" type="danger">确定</el-button>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          <!-- @click="add_fangan" -->
          <!-- <div class="box box_box3">
            
            <div class="box3">
              <c-icon name="xinzeng" style="fill: red; font-size: 38px"></c-icon>
              <p style="color: red">新增</p>
            </div>
          </div> -->
        </div>

        <div class="fapiao_shezhi_fooder" style="text-align: center;">
          <el-button @click="submitInvoice()" type="danger" style="margin：0 auto; margin-top: 30px;">提交发票设置</el-button>
          <!-- <p> <i class="el-icon-info" style="color:#aaa;font-size: 20px;"></i></i>  <span>当前开票服务：</span> <span>票通</span>  </p>
          <div class="fapiao_shehzi_xufei_cz">
            <div>
               <p v-if="true">开通日期：2021年21日-2022年2月20  <span class="tishi_riqi">剩余日期：30天</span>  为保证正常使用开票功能，请尽快续费 <span class="xufei_btn">续费</span> </p> 
               <p v-else>开通日期：2021年21日-2022年2月20  <span>剩余日期：272天</span>  <span class="xufei_btn">续费</span> </p> 
               <p> 如有疑问，请联系客服 400-4204410</p>
             </div>
            <div>  
               <p>您暂未选择任何开票服务，客户将不能进行自主开票，请尽快开通   <span class="xufei_btn">开通</span> </p> 
            </div>
          </div> -->
        </div>

    </div>


    <div class="zhifu_shezhi" v-if="navindex == '2'">
      <div class="mendian_search">
        <el-row :gutter="24">
          <el-col :span="10" :offset="1"
            ><div class="grid-content bg-purple">
              <span class="search_line"></span>
              <span class="search_txt">适用门店：</span>

              <!-- 设置了 单选 -->
              <el-select
                v-if="mendian_dan_duo_qiehuan"
                v-model="zhifu_value_danxuan"
                placeholder="请选择"
                @change="zhifu_mendian_danxaun"
              >
                <el-option
                  v-for="item in zhifushezhi_options"
                  :key="item.shop_id"
                  :label="item.shop_name"
                  :value="item.shop_id"
                >
                  <span style="float: left">{{ item.shop_name }}</span>
                  <span
                    v-if="item.flag_set == '1'"
                    style="float: right; color: #fc4353; font-size: 13px"
                    >已设置</span
                  >
                  <span
                    v-else
                    style="float: right; color: #8492a6; font-size: 13px"
                    >未设置</span
                  >

                  <!-- <span :class="[item.flag_set=='1'?'yishezhi':'weishezhi']">{{item.flag_set=='1'?'已设置':'未设置'}}</span> -->
                </el-option>
              </el-select>
              <!-- 没设置 多选 -->
              <el-select
                v-else
                v-model="zhifu_value"
                multiple
                collapse-tags
                placeholder="请选择"
                @change="zhifu_mendian_s"
              >
                <el-option
                  v-for="item in zhifushezhi_options"
                  :key="item.shop_id"
                  :label="item.shop_name"
                  :value="item.shop_id"
                >
                  <span style="float: left">{{ item.shop_name }}</span>
                  <span
                    v-if="item.flag_set == '1'"
                    style="float: right; color: #fc4353; font-size: 13px"
                    >已设置</span
                  >
                  <span
                    v-else
                    style="float: right; color: #8492a6; font-size: 13px"
                    >未设置</span
                  >

                  <!-- <span :class="[item.flag_set=='1'?'yishezhi':'weishezhi']">{{item.flag_set=='1'?'已设置':'未设置'}}</span> -->
                </el-option>
              </el-select>
            </div></el-col
          >
        </el-row>
      </div>

      <div class="zhifu_fangshi_he">
        <el-row :gutter="24">
          <el-col :span="10" :offset="1"
            ><div class="grid-content bg-purple zhifu_xt">
              <div class="top_zhifu_xt">支付方式 ( 系统 )</div>

              <div
                class="xuanxiang_box"
                v-for="(item, index) in XT_zhifu"
                :key="this"
              >
                <span>{{ item.pay_type_disp }}</span>
                <el-radio
                  v-model="item.flag_use"
                  label="0"
                  @change="XT_xuanze(item)"
                  >停用</el-radio
                >
                <el-radio
                  v-model="item.flag_use"
                  label="1"
                  @change="XT_xuanze(item)"
                  >启用</el-radio
                >
              </div>
            </div></el-col
          >

          <el-col :span="10" :offset="1"
            ><div class="grid-content bg-purple zhifu_zdy">
              <div class="top_zhifu_zdy">支付方式 ( 自定义 )</div>
              <div class="zdy_duoxuan">
                <div
                  class="zdy_duoxuan_zhifu"
                  v-for="(item, index) in zidingyi"
                  :key="this"
                >
                  <el-checkbox
                    v-model="item.checked"
                    @change="handlechecbox(item)"
                  >
                    {{ item.pay_type_disp }}
                  </el-checkbox>
                  <span
                    class="duoxuan_shanchu"
                    @click="delete_set_pay_type(item)"
                    >删除</span
                  >
                </div>
                <!-- <div class="zdy_duoxuan_zhifu"> <el-checkbox v-model="checked">  备选项  </el-checkbox>   <span class="duoxuan_shanchu">删除</span> </div>
                    <div class="zdy_duoxuan_zhifu"> <el-checkbox v-model="checked">  备选项  </el-checkbox>   <span class="duoxuan_shanchu">删除</span> </div> -->
              </div>
              <div>
                <el-input
                  v-model="add_zhifu_fangshi"
                  placeholder="请输入自定义支付方式"
                ></el-input>
              </div>
              <div class="add_fangshi">
                <div class="add_btn" @click="insert_set_pay_type">增加方式</div>
              </div>
            </div></el-col
          >
        </el-row>

        <div class="xuanze_baocun">
          <div class="baocun_btn" @click="save_set_pay_type">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  query_shop_info_list,
  insert_set_pay_type,
  delete_set_pay_type,
  save_set_pay_type,
  query_set_pay_type_list,
  query_set_pay_type_shop_list,
  insert_print_rule,
  query_print_rule,
  query_shop_print_list,
  query_has_set_print_shop_list,
  insert_inv_set,
  query_shop_inv_set
} from "../../../api/store";

import { query_prod_tree_of_1cat_2prod } from "../../../api/foodqiye";

import { query_category_tree } from "../../../api/public";
export default {
  data() {
    return {
      navindex: "0", // 切换打印 发票 支付设置
      dyj_options: [], // 打印机的店铺list

      dayin_dianpu: "", // 打印店铺的名称 shop_id

      flag_use: "1", // 是否使用打印机
      printer_num: "0", // 打印机数量
      flage_disp_times: "1", // 是否显示打印次数

      list_printer: [
        {
          printer_id: "", // 打印机id
          // print_num: "", // 打印张数
          print_content_type: [], // 打印类型的内容 1预结账单 点菜单 3 全部菜系
          expected: "", // 预结账单,
          menu: "", // 点菜单
          Alldishes: "", // 全部菜系

          cat_ids: [], // 菜系
          print_mode: "", // 打印方式：1全打，2隔断
        },
      ],

      dayyinji_list: [], // 打印机列表
      prod_list: [], // 菜品的列表

      fangshi_name: "",
      dayin_fangshi: [
        {
          id: "2",
          dayin_name: "隔断",
        },
        {
          id: "1",
          dayin_name: "连续",
        },
      ], // 打印方式

      //  == 发票设置
      kaipiao_fs: "", // 选择开票方式  earl：这个页面相当乱，后来的人请仔细分辨，这个代码尽力了。
      serarch_xz: false,
      is_mendian_or_kaipiaofangshi: false,
      options_value_box: [],
      submitInvoiceForm: {},
      kaipiao_fs_arr: [
        {id: '1', label: '自动开票'},
        {id: '2', label: '手动开票'},
        {id: '3', label: '扫码开票'}
      ],

      // 支付设置
      mendian_dan_duo_qiehuan: false,
      zhifushezhi_options: [], // 门店
      zhifu_value: [],
      zhifu_value_danxuan: "", // 门店单选
      index: "0",

      // 系统的支付设置
      XT_zhifu: [],

      // 自定义  支付设置
      zidingyi: [],

      add_zhifu_fangshi: "",

      checkList: [], // del del
    };
  },
  created() {},
  mounted() {
    // this.query_shop_info_list()
    this.query_set_pay_type_shop_list();

    // this.query_has_set_print_shop_list(); // 打印机门店 // 

    this.query_prod_tree_of_1cat_2prod(); // 菜品树

    //    this.query_print_rule() // 打印规则
  },

  methods: {
    dayin_fangshi_fangfa(val) {
      console.log(val);
      console.log(this.list_printer);
    },
    dayin_ji_xuanze_dayin() {
      console.log(this.checkList);
    },

    query_prod_tree_of_1cat_2prod() {
      query_prod_tree_of_1cat_2prod({
        data: {
          ent_id: this.$store.state.ent_id,
        },
      }).then((res) => {
        console.log("菜系树树树", res);
        console.log("菜系树树树", res.body.data);
        let prod_list = res.body.data;
        for (let i in prod_list) {
          prod_list[i].prod_name = prod_list[i].cat_name; // prod_id
          prod_list[i].prod_id = prod_list[i].cat_id; // prod_id
        }
        this.prod_list = res.body.data;
        this.query_has_set_print_shop_list(); // 打印机门店
      });
    },

    // 查询门店
    query_shop_info_list() {
      query_shop_info_list({
        data: {
          ent_id: this.$store.state.ent_id,
        },
      }).then((res) => {
        console.log("获取门店列表", res);
        if (res.code == 200) {
          this.dyj_options = res.body.data;
          console.log('this.dyj_options', this.dyj_options)
          if(this.navindex == '1') {
            this.query_shop_inv_set()
          }
          // this.zhifushezhi_options = res.body.data;
          // this.zhifu_value.push(res.body.data[0].shop_id);

          // this.query_set_pay_type_list();
        }
      });
    },

    // 总切换
    handlechange(index) {
      this.navindex = index;
      if(index=='1') {
        this.query_shop_info_list()
      }
    },
    // 查询门店  支付设置的门店
    query_set_pay_type_shop_list() {
      query_set_pay_type_shop_list({
        data: {
          ent_id: this.$store.state.ent_id,
        },
      }).then((res) => {
        console.log(
          "获取门店列表===query_set_pay_type_shop_list",
          res.body.data,
          "|||",
          res
        );
        
        if (res.code == 200) {
          this.dyj_options = res.body.data;
          let zhifushezhi_options = res.body.data;
          this.mendian_dan_duo_qiehuan = zhifushezhi_options.some((item) => {
            return item.flag_set == 1;
          }); // 有没有设置过
          let shop_danxuan = [];
          for (let i in zhifushezhi_options) {
            if (zhifushezhi_options[i].flag_set == 1) {
              shop_danxuan.push(zhifushezhi_options[i].shop_id);
            }
          }
          // this.zhifu_value_danxuan = shop_danxuan.length=='0'?'':shop_danxuan[0] // 支付设置的门店选择  zhifushezhi_options[i].shop_id
          this.zhifu_value_danxuan =
            shop_danxuan.length == "0"
              ? zhifushezhi_options[0].shop_id
              : shop_danxuan[0]; // 支付设置 的门店选择
          this.zhifu_value.push(res.body.data[0].shop_id); // 支付设置 未设置
          console.log(
            "this.mendian_dan_duo_qiehuan",
            this.mendian_dan_duo_qiehuan
          );
          this.zhifushezhi_options = zhifushezhi_options;

          // this.zhifu_value.push(res.body.data[0].shop_id)
          this.query_set_pay_type_list();
          this.handle_dayin_dianpu(this.dyj_options[0].shop_id)
        }
      });
    },
    // 支付设置 查询支付方式
    query_set_pay_type_list() {
      console.log(this.zhifushezhi_options);
      let index = this.index;
      let data = {
        data: {
          ent_id: this.$store.state.ent_id,
          shop_id: this.zhifushezhi_options[index].shop_id,
        },
      };
      query_set_pay_type_list(data).then((res) => {
        console.log("查询支付方式", res.body.data);
        if (res.code == "200") {
          let type_list = res.body.data;
          let XT_zhifu = []; // 系统的
          let zidingyi = []; //
          for (let ty in type_list) {
            if (type_list[ty].gen_type == "1") {
              XT_zhifu.push(type_list[ty]);
            }
            if (!type_list[ty].gen_type || type_list[ty].gen_type == "2") {
              type_list[ty].checked = type_list[ty].flag_use
                ? type_list[ty].flag_use == "1"
                  ? true
                  : false
                : false;
              type_list[ty].gen_type = "2";
              zidingyi.push(type_list[ty]);
            }
          }
          this.XT_zhifu = XT_zhifu; // 系统的支付方式 集
          this.zidingyi = zidingyi; // 自定义  支付设置
        }
      });
    },

    // 新增支付方式
    insert_set_pay_type() {
      if (this.add_zhifu_fangshi != "") {
        let data = {
          data: {
            ent_id: this.$store.state.ent_id,
            shop_id: this.zhifu_value.join(","),
            pay_type: String(Date.parse(new Date())), // 支付方式
            pay_type_disp: this.add_zhifu_fangshi, // 支付方式显示
            user_id: this.$store.state.user_id,
          },
        };
        console.log(data);
        insert_set_pay_type(data).then((res) => {
          console.log("新增支付方式", res);
          if (res.code == "200") {
            this.add_zhifu_fangshi = "";
          }
          this.query_set_pay_type_list();
        });
      } else {
        this.$message({
          message: "支付方式名不能为空",
          type: "warning",
        });
      }
    },

    // 删除支付方式
    delete_set_pay_type(item) {
      let data = {
        data: {
          ent_id: this.$store.state.ent_id,
          shop_id: this.zhifu_value.join(","),
          pay_type: item.pay_type,
          user_id: this.$store.state.user_id,
        },
      };
      delete_set_pay_type(data).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.query_set_pay_type_list();
        }
      });
    },
    // 保存的支付方式
    save_set_pay_type() {
      let XT_zhifu_list = this.XT_zhifu;
      let zidingyi = this.zidingyi; // 自定义
      let list = [];
      for (let i in XT_zhifu_list) {
        list.push({
          pay_type: XT_zhifu_list[i].pay_type,
          pay_type_disp: XT_zhifu_list[i].pay_type_disp,
          flag_use: XT_zhifu_list[i].flag_use, // 是否使用打印机
          gen_type: XT_zhifu_list[i].gen_type,
        });
      }
      for (let i in zidingyi) {
        list.push({
          pay_type: zidingyi[i].pay_type,
          pay_type_disp: zidingyi[i].pay_type_disp,
          flag_use: zidingyi[i].checked ? "1" : "0", // 是否使用打印机
          gen_type: zidingyi[i].gen_type,
        });
      }

      let data = {
        data: {
          list: list,
          ent_id: this.$store.state.ent_id,
          user_id: this.$store.state.user_id,
          shop_id:
            this.zhifu_value.length == 0
              ? this.zhifu_value_danxuan
              : this.zhifu_value.join(","),
        },
      };
      console.log(data);
      // return
      save_set_pay_type(data).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }
      });
    },
    // ========打印机
    // 选择打印机
    handle_printerList(val) {
      console.log("===", val);
      //   let printerList = val;
      let printer_list = this.dayyinji_list;

      let arr = [];
      for (const k in this.list_printer) {
        if (this.list_printer[k].printer_id) {
          arr.push(this.list_printer[k].printer_id);
        }
      }
      for (const i in printer_list) {
        if (arr.includes(printer_list[i].msn)) {
          printer_list[i].dis = true;
        } else {
          printer_list[i].dis = false;
        }
      }
    },
    // 选择菜系
    xuanzecai(val) {
      console.log("选择菜系", val);
    },
    handle_dayin_dianpu(val) {
      // 切换门店
      this.dayin_dianpu = val;
      this.query_shop_print_list();
      this.query_print_rule(); // 切换打印机系统  记得解开
    },
    query_has_set_print_shop_list() {
      // 打印机门店
      query_has_set_print_shop_list({
        data: {
          ent_id: this.$store.state.ent_id,
        },
      }).then((res) => {
        console.log(
          "获取打印机门店列表===query_has_set_print_shop_list",
          res.body.data, "|||", res
        );
        if (res.code == 200) {
          let dy_shop_list = res.body.data;
          this.dayin_dianpu = dy_shop_list[0].shop_id;
          this.query_shop_print_list(); // 打印机信息 //记得解开
          this.query_print_rule(); // 打印规则  记得解开
        }
      });
    },

    query_print_rule() {
      let data = {
        data: {
          ent_id: this.$store.state.ent_id,
          shop_id: this.dayin_dianpu,
        },
      };

      query_print_rule(data).then((res) => {
        console.log("打印规则打印规则", res);
        if (res.code == 200) {
          console.log("打印规则打印规则", res.body.data);
          let data = res.body.data;
          let list = data.list;
          this.flag_use = data.flag_use;
          this.printer_num = data.printer_num;

          let list_printer = this.list_printer;
          console.log(list_printer, "==========", list);

          // cat_ids
          for (let a in list_printer) {
            for (let b in list) {
              if (list_printer[a].printer_id == list[b].printer_id) {
                list_printer[a].print_content_type.push(
                  list[b].print_content_type
                );
                if (list[b].print_content_type == "1") {
                  list_printer[a].expected = list[b].print_num; // 预结账单,
                }
                if (list[b].print_content_type == "2") {
                  list_printer[a].menu = list[b].print_num; // 点菜单
                }
                if (list[b].print_content_type == "3") {
                  let arr = list[b].prod_ids? list[b].prod_ids.split(","): [] // 菜品
                  let catarr = list[b].cat_ids? list[b].cat_ids.split(","): [] // 菜系
                  let treecatch = this.prod_list // 树形图
                  let list_printer_catids = []
                  ;

                  for (const i in catarr) { // 菜系
                    for (const a in treecatch) { // 树里的菜系
                    if (treecatch[a].cat_id == catarr[i]) {
                      list_printer_catids.push([catarr[i]])
                      for (let b in arr) { // 菜品
                          for (let c in treecatch[a].prod_children) { // 菜品
                              if (treecatch[a].prod_children[c].prod_id == arr[b]) {
                                list_printer_catids.push([catarr[i],arr[b]])
                              }
                          }
                      }
                    }
                    }
                  }
                  list_printer[a].print_mode = list[b].print_mode;
                  // list_printer[a].cat_ids = (list[b].prod_ids+','+list[b].cat_ids).split(',')
                  list_printer[a].cat_ids = list_printer_catids
                  list_printer[a].Alldishes = list[b].print_num; // 全部菜系
                }
              }
            }
          }
          console.log(
            "=========================",
            this.$refs["cascader_caipin"]
          );

          console.log(list_printer, "==========", list);
          this.list_printer = list_printer;
        }
      });
    },
    // 查询打印机 信息
    query_shop_print_list() {
      // 打印机信息
      let data = {
        data: {
          ent_id: this.$store.state.ent_id,
          shop_id: this.dayin_dianpu,
        },
      };
      if (this.dayin_dianpu != "") {
        query_shop_print_list(data).then((res) => {
          console.log(res);
          console.log("打印机信息", res.body.data);
          let dyj_xx = res.body.data;
          let list_printer = [];
          for (let i in dyj_xx) {
            dyj_xx[i].name_linshi = `打印机${i + 1}`;

            list_printer.push({
              // printer_id: "", // 打印机id
              // print_num: "", // 打印张数
              // print_content_type: "1", // 打印类型的内容 1预结账单 2结账单 3 全部菜系
              // cat_ids: "", // 菜系
              printer_id: dyj_xx[i].msn, // 打印机id
              print_content_type: [], // 打印类型的内容 1预结账单 点菜单 3 全部菜系
              expected: "", // 预结账单,
              menu: "", // 点菜单
              Alldishes: "", // 全部菜系
              cat_ids: [], // 菜系
              print_mode: "1", // 打印方式：1全打，2隔断
            });
          }
          this.dayyinji_list = dyj_xx;
          this.list_printer = list_printer;
        });
      }
    },
    // 保存
    printer_baocun() {
      console.log("保存");

      let list_printer = this.list_printer;
      console.log('list_printer---------', list_printer);

      for(let i=0; i<list_printer.length; i++) {
        let item = list_printer[i]
        if(!item.print_mode) {
          this.$message({message: `打印机设置不完整，请选择第 ${i+1} 单元的打印方式！`, type: "error"});
          return
        }
      }

      let list = [];
      for (let a in list_printer) {
        for (let b in list_printer[a].print_content_type) {
          if (list_printer[a].print_content_type[b] == "1") {
            list.push({
              printer_id: list_printer[a].printer_id, // 打印机id
              print_content_type: "1",
              print_num: list_printer[a].expected, // 打印数量
            });
          }
          if (list_printer[a].print_content_type[b] == "2") {
            list.push({
              printer_id: list_printer[a].printer_id, // 打印机id
              print_content_type: "2",
              print_num: list_printer[a].menu, // 打印数量
            });
          }
          if (list_printer[a].print_content_type[b] == "3") {
            // let  arr = [...new Set(list_printer[a].cat_ids.flat(Infinity))].join(',')
            // console.log('====',arr  );

            list.push({
              printer_id: list_printer[a].printer_id, // 打印机id
              print_mode: list_printer[a].print_mode, // 打印的方式 菜品的
              print_content_type: "3",
              print_num: list_printer[a].Alldishes, // 打印数量
              cat_ids: [...new Set(list_printer[a].cat_ids.flat(Infinity))].join(',')  //Array.isArray(list_printer[a].cat_ids)? new Set([list_printer[a].cat_ids.flat(Infinity)]).join(",") : list_printer[a].cat_ids, // 菜品的id
            });
          }
        }
      }
      let data = {
        data: {
          ent_id: this.$store.state.ent_id,
          shop_id: this.dayin_dianpu,
          flag_use: this.flag_use,
          printer_num: this.printer_num,
          // flag_disp_times:this.flage_disp_times,
          list_printer: list,
        },
      };
      console.log("保存", data);
      insert_print_rule(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.query_print_rule();
        } else {
          this.$message({
            message: "保存失败",
            type: "error",
          });
        }
      });
    },


    // ------------打印
    // 打印数量
    // handleChange_num(value) {
    //   console.log(value);
    // },
    // 获取发票配置
    async query_shop_inv_set() {
      let params = {
        ent_id: this.$store.state.ent_id
      }
      let res = await query_shop_inv_set({data: params})
      if(res && res.code == 200 && res.data != "null") {
        let ent_id = res.body.data.ent_id
        let data = JSON.parse(res.body.data.list_str)
        console.log('data', data)
        this.kaipiao_fs = data.open_type
        this.submitInvoiceForm.open_type = data.open_type
        this.options_value_box = data.shopList
      } else {
        this.$message({message: res.msg, type: 'error'})
      }
    },
    // 新增门店
    add_mendian() {
      this.serarch_xz = true;
      this.is_mendian_or_kaipiaofangshi = false;
    },
    add_kaipiao() {
      this.serarch_xz = true;
      this.is_mendian_or_kaipiaofangshi = true;
    },
    // 请选择框改变事件
    changeItem(val,index,options,item) {
        //  console.log(val,index,options,item);
         let alldata = this.options_value_box
          if( JSON.stringify(alldata).indexOf(val.shop_id + '') !== -1){
            this.$message.warning('请勿重复添加门店')
          }else{
          this.options_value_box.push(val)

          }
    },
    del_mendian(item,index) {
      this.options_value_box.splice(index,1)
    },
    push_caipin(valx) {
      this.submitInvoiceForm.open_type = valx
      this.serarch_xz = false
    },
    async submitInvoice() {
      let mendian = []
      let shopList = []
      this.options_value_box.map(item=>{
        mendian.push(item.shop_id)
        shopList.push({shop_id: item.shop_id, shop_name: item.shop_name})
      })
      let params = {
        ent_id: this.$store.state.ent_id,
        shop_id: mendian.join(','),
        title: '方案一',
        open_type: this.submitInvoiceForm.open_type,
        user_id: this.$store.state.user_id,
        shopList: shopList // 这个额外字段是为了回显
      } 
      // 10-28 虎哥说回显就使用list_str中的对象字符串，没办法只能将所有参数存入params，然后放进来。
      // 里面的字段虎哥口头表达，没有聊天记录，接口记录在
      // 里面的字段名千万不能改，如果修改了，回显会出问题（不显示对应字段）。
      params.list_str = JSON.stringify(params)
      let res = await insert_inv_set({data: params})
      if(res && res.code == 200) {
        this.$message({message: '发票设置成功', type:'success'})
      } else {
        this.$message({message: res.msg, type:'error'})
      }
    },
    // ===============支付设置的方法
    // 选择门店
    zhifu_mendian_s(val) {
      console.log(val);
      console.log("zhifu_value", this.zhifu_value);
    },
    // 单选
    zhifu_mendian_danxaun() {
      console.log(this.zhifu_value_danxuan);
      let zhifushezhi_options = this.zhifushezhi_options;
      for (let i in zhifushezhi_options) {
        if (zhifushezhi_options[i].shop_id == this.zhifu_value_danxuan) {
          this.index = i;
        }
      }
      this.query_set_pay_type_list(); // 查询支付方式
    },
    handlechecbox(val) {
      console.log(val);
    },
    XT_xuanze(item) {
      console.log(item);
      console.log("====", this.XT_zhifu);
    },
    getStatusLabel(val, list, id, label) {
      for(let i=0; i<list.length; i++) {
        if(list[i][id] == val) {
          return list[i][label]
        }
      }
    }
  },
};
</script>

<style lang="scss">
.yin_j {
  .el-input-group {
    .el-input__inner {
      border: 1px solid #333;
    }
    .el-input-group__append {
      .el-button {
        color: #fff;
        background-color: red;
      }
    }
  }
}
.duoxuan_box {
  .search {
    .el-cascader{
      width: 360px;
    }
  }
}
</style>

<style lang="scss" scoped>
.yingjian_shezhi_box {
  width: 100%;
}

// 设置金额的数值
.yishehzi {
  float: right;
  color: red;
  font-size: 13px;
}
.weishezhi {
  float: right;
  color: #8492a6;
  font-size: 13px;
}

.Mar-nav {
  width: 100%;
  height: 80px;
  font-size: 15px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;

  p {
    width: 120px;
    height: 77px;
    text-align: center;
    line-height: 77px;
  }

  .psel {
    color: red;
    border-bottom: 3px solid red;
  }

  .el-row {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
    height: 100%;
  }
  .bg-purple {
    background: #fff;
  }
  .grid-content {
    border-radius: 4px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}

.yingjian_dayin {
  width: 100%;
  // height: 640px;
  margin: 30px 0 0 0;
  border-radius: 8px;
  background-color: #fff;
  .XZ_input {
    width: 100%;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .span_line {
      display: block;
      width: 6px;
      height: 40px;
      border-radius: 8px;
      background-color: red;
      margin: 0 20px 0 30px;
    }
    .span_text {
      font-size: 18px;
    }
  }

  .xaunze_dayinji_data {
    margin: 30px 0 0 0;
    padding: 0 0 0 60px;
  }

  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  // .bg-purple-dark {
  //     background: #99a9bf;
  // }
  // .bg-purple {
  //     background: #d3dce6;
  // }
  .yin_j {
    background: #fff;
  }
  .yin_j {
    border-radius: 4px;
    height: 36px;
    .yin_j_span {
      font-size: 16px;
    }
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .radio_xz {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.zhifingdayin_neirong {
  width: 80%;
  padding: 20px 0 0 50px;
  // border: 1px solid;
  // height: 420px;
  box-sizing: border-box;
  // overflow-y: auto;
  margin: 20px 0 0 0;
  box-shadow: 0px -1px 5px 2px rgba(0,0,0,0.1) inset;
  .dyj_box {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .dyj_box1 {
    // width: 500px;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .dynr_txt {
      padding-top: 30px;
      width: 70px;
      font-size: 16px;
    }
    .dayin_nairong_xz {
      // width: 600px;
      width: 99%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // height: 300px;
      overflow-y: auto;
      padding: 10px;
      box-sizing: border-box;
      margin:  5px 0 0 0;
      overflow: hidden;
      .search {
        width: 600px;
        display: flex;
        margin: 6px 0;
        .search_name {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 10px 0 0;
        }
      }
      // label {
      //   // width: 100%;
      //   // transform: scale(1.5);
      // }
      .el-radio {
        // transform: scale(1.2);
        padding: 5px 0px;
      }
      .duoxuan_box {
        // padding: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;
      // box-shadow: 0px -1px 5px 2px rgba(0,0,0,0.1);
        .search {
          margin: 4px 0 4px 6px;
        }
      }
    }
  }
  .dyj_box2 {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .dyj_bt {
      width: 100px;
      margin: 0 20px 0 0;
      font-size: 16px;
    }
  }
  .dyj_box3 {
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0 50px 0;
    box-sizing: border-box;
    .dyj_bt {
      width: 100px;
      margin: 0 20px 13px 0;
      font-size: 16px;
    }
  }
  .zddy_txt {
    font-size: 16px;
    padding: 0 0 20px 0;
  }
  .dyj_bt {
    font-size: 16px;
  }

  .el-row {
    margin-bottom: 40px;
    height: 340px; // 支付设置高度
    overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
    height: 300px;
  }
  .bg_purple_bt {
    background: #fff;
  }
  //   .grid-content {
  //     border-radius: 4px;
  //     height: 240px;
  //   }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
.dayin_baocun {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .dayin_baocun_btn {
    width: 120px;
    height: 50px;
    color: #fff;
    font-size: 20px;
    background-color: #fc4040;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fapiao_shezhi {
  width: 100%;
  height: 640px;
  margin: 30px 0 0 0;
  padding: 8px 0 0 0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-flow: row wrap;

  .box {
    width: 300px;
    height: 620px;
    margin-left: 60px;
    margin-top: 30px;
    border-radius: 6px;
    box-shadow: 4px 10px 14px #d4d4d4;
    position: relative;
    > .title {
      position: relative;
      width: 100%;
      height: 50px;
      background: rgb(255, 55, 55);
      border-radius: 6px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      font-size: 18px;
      color: #fff;
      text-align: center;
      line-height: 50px;
      .c-icon {
        position: absolute;
        right: 0px;
        fill: #fff;
        top: 0px;
      }
    }
  }
  .box_ul {
    width: 100%;
    position: relative;
    //   height:calc(50% - 60px);
    height: 50%;
    box-sizing: border-box;
    font-size: 14px;
    > ul {
      padding: 20px;
      padding-bottom: 10px;
      > .div-button {
        margin-top: 20px;
        text-align: center;
      }
      li {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        line-height: 30px;
        justify-content: space-between;
        > span {
          width: 10px;
          height: 10px;
          background: #b1b1b1;
          border-radius: 10px;
        }
        > p {
          width: 100px;
          color: #8a8989;
          &:last-child {
            width: 28px;
            color: rgb(255, 89, 89);
          }
        }
      }
    }
  }
  .box1_ul {
    width: 100%;
    position: relative;
    height: calc(46% - 60px);
    box-sizing: border-box;
    font-size: 14px;
    border-top: 1px solid #ccc;
    > ul {
      padding: 20px;
      padding-bottom: 10px;
      > .div-button {
        // margin-top: 20px;
        text-align: center;
      }
      li {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        line-height: 30px;
        justify-content: space-between;
        > span {
          width: 10px;
          height: 10px;
          background: #b1b1b1;
          border-radius: 10px;
        }
        > p {
          width: 100px;
          color: #8a8989;
          &:last-child {
            width: 100px;
            color: rgb(255, 89, 89);
          }
        }
      }
    }
  }

  .box > .sidbar {
    position: absolute;
    border-radius: 6px;
    width: 260px;
    height: 100%;
    background: #fff;
    box-shadow: 4px 10px 14px #c5c5c5;
    top: 0;
    right: -280px;
    font-size: 20px;
    z-index: 2;
    // display: none;
    > .main {
      height: 80%;
      padding: 20px;
      overflow: auto;
      .mybox {
        display: flex;
        flex-flow: column nowrap;
        font-size: 14px;
        > li {
          padding-left: 20px;
          text-align: left;
          line-height: 22px;
          color: #8a8989;
          display: inline-flex;
          .c-icon {
            // display: none;
            display: inline-flex;
            position: absolute;
            right: 20px;
            fill: red;
          }
          //修改状态 请选择
          &.end_active {
            .c-icon {
              display: inline-flex;
              position: absolute;
              right: 20px;
              fill: red;
            }
          }
        }
      }
      .tijiao {
        position: absolute;
        text-align: center;
        left: 50%;
        margin-left: -36px;
        bottom: 20px;
      }
    }

    &.active {
      display: block;
    }
    > .title {
      width: 100%;
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      background: rgb(255, 55, 55);
      height: 30px;
    }
  }

  .box_box3 {
    width: 300px;
    // height: 200px;
    margin-left: 60px;
    margin-top: 30px;
    border-radius: 6px;
    box-shadow: 4px 10px 14px #d4d4d4;

    height: 30%;
    align-self: flex-start;
    display: flex;
    max-height: 300px;
    justify-content: center;
    align-items: center;
    > .box3 {
      display: flex;
      width: 90px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  border: none;
  height: calc(100% + 17px);
  //   height:100%;
}

.fapiao_shezhi_fooder{
  width: 100%;
  height: 100px;
  font-size: 16px;
  margin-top: 20px;
  background-color: #fff;
  &>p{
    font-size: 18px;
    color: #333;
  }
  .fapiao_shehzi_xufei_cz{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .tishi_riqi{
      display: inline-block;
      color: #fc4040;
      margin: 0 20px;
    }
    .xufei_btn{
      display: inline-block;
      width: 60px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      background-color: #fc4040;
      border-radius: 6px;
    }
  }
}

.zhifu_shezhi {
  width: 100%;
  height: 640px;
  margin: 30px 0 0 0;
  padding: 8px 0 0 0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  .mendian_search {
    width: 100%;
    height: 50px;
    // background-color: red;
    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    // .bg-purple-dark {
    //     background: #99a9bf;
    // }
    .bg-purple {
      // background: #d3dce6;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .search_line {
        display: block;
        width: 4px;
        height: 36px;
        background-color: red;
        border-radius: 8px;
      }
      .search_txt {
        display: block;
        width: 100px;
        font-size: 16px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        color: #333;
      }
    }
    // .bg-purple-light {
    //     background: #e5e9f2;
    // }
    .grid-content {
      border-radius: 4px;
      min-height: 50px;
    }
    // .row-bg {
    //     padding: 10px 0;
    //     background-color: #f9fafc;
    // }
  }

  .zhifu_fangshi_he {
    width: 100%;
    height: 360px;
    // background-color: #ccc;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    .bg-purple {
      // background: #d3dce6;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 300px;
    }
    .zhifu_xt {
      .top_zhifu_xt {
        width: 100%;
        height: 20px;
        font-size: 18px;
      }
      .xuanxiang_box {
        width: 100%;
        height: 50px;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span {
          width: 160px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 18px;
        }
        .el-radio {
          transform: scale(1.1);
        }
      }
    }
    .zhifu_zdy {
      position: relative;
      .top_zhifu_zdy {
        width: 100%;
        height: 20px;
        font-size: 18px;
        margin: 0 0 20px 0;
      }
      .zdy_duoxuan {
        width: 90%;
        max-height: 160px;
        overflow-y: auto;
        .zdy_duoxuan_zhifu {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .el-checkbox {
            transform: scale(1.1);
          }
          .duoxuan_shanchu {
            font-size: 16px;
            color: red;
          }
        }
      }
      .add_fangshi {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .add_btn {
          width: 100px;
          height: 40px;
          border: 1px solid red;
          font-size: 18px;
          text-align: center;
          line-height: 40px;
          border-radius: 8px;
          color: red;
        }
      }
    }
    .xuanze_baocun {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      .baocun_btn {
        width: 120px;
        height: 50px;
        background-color: red;
        font-size: 18px;
        color: #fff;
        border-radius: 6px;
        text-align: center;
        line-height: 50px;
      }
    }
  }
}
</style>